import { isEU } from "../Functions/functions";

const getModelPrice = (model, loggedInUser, order = null) => {
    if (order === null) {
        return getModelPriceWithoutOrder(model, loggedInUser);
    }
    if (order.lock_order_prices === true) {
        return (
            <>
                {<>€</>}
                {parseFloat(model.pivot.price * model.pivot.amount).toFixed(2)}
            </>
        );
    }

    if (/[a-z]/i.test(model.pivot.price)) {
        return <>{model.pivot.price}</>;
    } else if (order.discount !== null && order.discount > 0) {
        let discount = 0;
        let modelPrice = (parseFloat(model.pivot.price) * model.pivot.amount).toFixed(2);

        if (
            loggedInUser.company !== null &&
            loggedInUser.company.allow_staffel_discount == true &&
            order.status === "quotation"
        ) {
            let originalPrice = parseFloat(model.pivot.price) * model.pivot.amount;
            modelPrice -= originalPrice - originalPrice * parseFloat(model.pivot.discount_multiplier);
            modelPrice = modelPrice.toFixed(2);
        } else if (order.status !== "quotation") {
            let originalPrice = parseFloat(model.pivot.price) * model.pivot.amount;
            let withStaffel = originalPrice * parseFloat(model.pivot.discount_multiplier);
            if (originalPrice !== withStaffel) {
                modelPrice = withStaffel.toFixed(2);
            }
        }

        if (modelPrice < 0) {
            modelPrice = (0).toFixed(2);
        }

        if (
            order.fixed_discount === false &&
            (parseFloat(model.pivot.price) * model.pivot.amount).toFixed(2) !== modelPrice
        ) {
            return (
                <>
                    <s>
                        {<>€</>}
                        {(parseFloat(model.pivot.price) * model.pivot.amount).toFixed(2)}
                    </s>
                    <br />
                    {<>€</>}
                    {modelPrice}
                </>
            );
        } else if (
            loggedInUser.company !== null &&
            loggedInUser.company.allow_staffel_discount == true &&
            (parseFloat(model.pivot.price) * model.pivot.amount * model.pivot.discount_multiplier).toFixed(2) !==
                modelPrice &&
            order.status === "quotation"
        ) {
            return (
                <>
                    <s>
                        {<>€</>}
                        {(parseFloat(model.pivot.price) * model.pivot.amount).toFixed(2)}
                    </s>
                    <br />
                    {<>€</>}
                    {modelPrice}
                </>
            );
        } else {
            if (
                !/[a-z]/i.test(model.pivot.price) &&
                model.pivot.price * model.pivot.amount !==
                    model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier &&
                order.status !== "quotation"
            ) {
                return (
                    <>
                        <s>€{parseFloat(model.pivot.price * model.pivot.amount).toFixed(2)}</s>
                        <br />€
                        {parseFloat(model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier).toFixed(
                            2
                        )}
                    </>
                );
            } else {
                return (
                    <>
                        {<>€</>}
                        {modelPrice}
                    </>
                );
            }
        }
    } else if (typeof order.discounts !== "undefined" && order.discounts.length > 0) {
        let modelPrice = 0;
        if (
            typeof model.pivot.discount_price !== "undefined" &&
            model.pivot.discount_price !== null &&
            order.discounts[0].fixed == false &&
            parseFloat(model.pivot.discount_price).toFixed(2) !==
                (parseFloat(model.pivot.price) * model.pivot.amount).toFixed(2)
        ) {
            modelPrice = parseFloat(model.pivot.discount_price);
            return (
                <>
                    <s>€{(parseFloat(model.pivot.price) * model.pivot.amount).toFixed(2)}</s>
                    <br />€{parseFloat(model.pivot.discount_price).toFixed(2)}
                </>
            );
        } else {
            // Check if model price is a valid number (not a price 'on request')
            if (!/[a-z]/i.test(model.pivot.price)) {
                if (
                    model.pivot.price * model.pivot.amount !==
                    model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier
                ) {
                    return (
                        <>
                            <s>€{parseFloat(model.pivot.price * model.pivot.amount).toFixed(2)}</s>
                            <br />€
                            {parseFloat(
                                model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier
                            ).toFixed(2)}
                        </>
                    );
                }
                modelPrice = parseFloat(model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier);
            } else {
                return <>{model.pivot.price}</>;
            }
        }

        if (
            !/[a-z]/i.test(model.pivot.price) &&
            model.pivot.price * model.pivot.amount !==
                model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier
        ) {
            return (
                <>
                    <s>€{parseFloat(model.pivot.price * model.pivot.amount).toFixed(2)}</s>
                    <br />€
                    {parseFloat(model.pivot.price * model.pivot.amount * model.pivot.discount_multiplier).toFixed(2)}
                </>
            );
        } else {
            return (
                <>
                    {<>€</>}
                    {modelPrice.toFixed(2)}
                </>
            );
        }
    } else {
        let regularPrice = model.pivot.amount * model.pivot.price;
        let discountPrice = regularPrice;

        if (model.pivot.discount_price !== null && model.pivot.discount_price !== "undefined") {
            discountPrice = parseFloat(model.pivot.discount_price);
        }

        if (order.discount !== null && order.discount > 0 && order.fixed_discount === false) {
            discountPrice -= regularPrice - regularPrice * (order.discount / 100);
        }

        if (
            loggedInUser.company !== null &&
            loggedInUser.company.allow_staffel_discount == true &&
            order.status === "quotation" &&
            !(model.pivot.discount_price !== null && model.pivot.discount_price !== "undefined")
        ) {
            discountPrice -= regularPrice - regularPrice * model.pivot.discount_multiplier;
        }

        if (regularPrice !== discountPrice) {
            return (
                <>
                    <s>€{regularPrice.toFixed(2)}</s>
                    <br />€{discountPrice.toFixed(2)}
                </>
            );
        } else {
            return (
                <>
                    {<>€</>}
                    {regularPrice.toFixed(2)}
                </>
            );
        }
    }
};

const getModelPriceWithoutOrder = (model, loggedInUser) => {
    if (/[a-z]/i.test(model.price)) {
        return <>{model.price}</>;
    }

    let regularPrice = model.amount * model.price;
    let discountPrice = regularPrice;

    if (typeof model.admin_edited !== "undefined" && model.admin_edited === true) {
        if (model.pivot.discount_price !== null) {
            return (
                <>
                    <s>&euro; {(parseFloat(model.pivot.price) * model.amount).toFixed(2)}</s>
                    <br />
                    <p className="price">&euro; {parseFloat(model.pivot.discount_price).toFixed(2)}</p>
                </>
            );
        } else {
            return (
                <>
                    <p className="price">&euro; {(parseFloat(model.pivot.price) * model.amount).toFixed(2)}</p>
                </>
            );
        }
    }

    if (
        typeof model.pivot !== "undefined" &&
        typeof model.pivot.discount_price !== "undefined" &&
        model.pivot.discount_price !== null
    ) {
        discountPrice = parseFloat(model.pivot.discount_price);
    } else if (loggedInUser.company !== null && loggedInUser.company.allow_staffel_discount == true) {
        discountPrice = regularPrice * model.discount_multiplier;
    }

    if (
        loggedInUser.company !== null &&
        loggedInUser.company.allow_staffel_discount == true &&
        regularPrice !== discountPrice
    ) {
        return (
            <>
                <s>
                    {!isNaN(regularPrice) && <>&euro;</>}
                    {!isNaN(regularPrice) && regularPrice.toFixed(2)}
                    {!isNaN(regularPrice) &&
                        typeof model.price === "string" &&
                        /[a-z]/i.test(regularPrice) &&
                        regularPrice}
                </s>
                <br />
                <p className="price">
                    {!isNaN(discountPrice) && <>&euro;</>}
                    {!isNaN(discountPrice) && discountPrice.toFixed(2)}
                    {!isNaN(discountPrice) &&
                        typeof model.price === "string" &&
                        /[a-z]/i.test(discountPrice) &&
                        discountPrice}
                    {typeof model.price === "string" && /[a-z]/i.test(model.price) && model.price}
                </p>
            </>
        );
    }

    return (
        <>
            {parseFloat(model.discount_multiplier) !== 1 &&
                !/[a-z]/i.test(model.price) &&
                parseFloat(model.price * model.amount) !== parseFloat(discountPrice) && (
                    <s>
                        {!isNaN(model.price) && <>€</>}
                        {!isNaN(model.price) && parseFloat(regularPrice).toFixed(2)}
                        {typeof model.price === "string" && /[a-z]/i.test(model.price) && model.price}
                    </s>
                )}
            <p className="price">
                {!isNaN(model.price) && <>&euro;</>}
                {!isNaN(discountPrice) && parseFloat(discountPrice).toFixed(2)}
                {typeof model.price === "string" && /[a-z]/i.test(model.price) && model.price}
            </p>
        </>
    );
};

const getShippingCosts = (order) => {
    let shippingCosts = 0;
    if (typeof order.delivery_price !== "undefined" && order.delivery_price !== null) {
        shippingCosts = parseFloat(order.delivery_price);
    }
    return shippingCosts;
};

const getCompanyDiscount = (order, loggedInUser, mandatoryProductSettings, onlyPercentages = false) => {
    // No company discount if prices are locked
    if (order.lock_order_prices === true) {
        return 0;
    }

    let totalCompanyDiscount = 0;
    let subtotal = 0;
    order.models.forEach((element) => {
        if (/[a-z]/i.test(element.pivot.price)) {
            // nothing if the price is on request
        } else if (element.pivot.discount_price !== "undefined" && element.pivot.discount_price !== null) {
            subtotal += parseFloat(element.pivot.discount_price);
        } else if (
            loggedInUser.company !== null &&
            loggedInUser.company.allow_staffel_discount == true &&
            order.status === "quotation"
        ) {
            subtotal += parseFloat(element.pivot.price * element.pivot.amount) * element.pivot.discount_multiplier;
        } else if (!/[a-z]/i.test(element.pivot.price)) {
            subtotal += parseFloat(element.pivot.price * element.pivot.amount);
        }
    });

    order.products.forEach((element) => {
        subtotal += parseFloat(element.price) * parseInt(element.quantity);
    });

    if (getExtraCosts(order, mandatoryProductSettings) !== false) {
        subtotal += parseFloat(getExtraCosts(order, mandatoryProductSettings));
    }

    // Get manual extra costs (from admin panel)
    order.extraCosts.forEach((element) => {
        if (element.name !== null && element.name.includes("Company discount")) {
            subtotal += parseFloat(element.price);
            totalCompanyDiscount += Math.abs(parseFloat(element.price));
        }
    });

    // Check if quotation and minimum order amount is set
    if (
        order.status === "quotation" &&
        loggedInUser.company !== null &&
        loggedInUser.company.minimum_order_amount > subtotal
    ) {
        subtotal = loggedInUser.company.minimum_order_amount;
    }

    if (totalCompanyDiscount > 0) {
        return totalCompanyDiscount;
    }

    subtotal -= parseFloat(getDiscountCodesAmount(order, mandatoryProductSettings, true));

    if (order?.minimum_order_amount !== null && order?.minimum_order_amount > 0) {
        subtotal += parseFloat(order?.minimum_order_amount);
    }

    if (typeof order.discount !== "undefined" && order.discount !== null) {
        if (order.fixed_discount) {
            if (!onlyPercentages) {
                return parseFloat(order.discount);
            } else {
                return 0;
            }
        } else {
            return parseFloat(subtotal * (order.discount / 100));
        }
    } else {
        if (
            typeof loggedInUser.company !== "undefined" &&
            loggedInUser.company !== null &&
            loggedInUser.company.discount_percentage > 0 &&
            loggedInUser.company.fixed_discount === true
        ) {
            if (order.status === "quotation") {
                return parseFloat(loggedInUser.company.discount_percentage);
            } else {
                return 0;
            }
        } else if (
            typeof loggedInUser.company !== "undefined" &&
            loggedInUser.company !== null &&
            loggedInUser.company.discount_percentage > 0 &&
            loggedInUser.company.fixed_discount === false &&
            order.status === "quotation"
        ) {
            return parseFloat(subtotal * (loggedInUser.company.discount_percentage / 100));
        }
    }

    return totalCompanyDiscount;
};

const getOrderDiscount = (order) => {
    // No company discount if prices are locked
    if (order.lock_order_prices === true) {
        return 0;
    }

    if (order.fixed_discount) {
        return parseFloat(order.discount);
    } else {
        return parseFloat(getSubtotalPrice(order) * (parseFloat(order.discount) / 100));
    }
};

const getDiscountCodesAmount = (order, mandatoryProductSettings, withFixedDiscounts = false) => {
    if (order.discounts === null || order.discounts.length === 0 || order.lock_order_prices === true) {
        return 0;
    }
    let subtotal = 0;
    let total = 0;
    order.models.forEach((element) => {
        if (/[a-z]/i.test(element.pivot.price)) {
            // nothing if the price is on request
        } else if (element.pivot.discount_price !== "undefined" && element.pivot.discount_price !== null) {
            subtotal += parseFloat(element.pivot.discount_price);
        } else if (!/[a-z]/i.test(element.pivot.price)) {
            subtotal += parseFloat(element.pivot.price * element.pivot.amount);
        }
    });

    order.products.forEach((element) => {
        subtotal += parseFloat(element.price) * parseInt(element.quantity);
    });

    // Add manual extra costs
    order.extraCosts.forEach((element) => {
        if (element.price > 0 && !element.name.includes("Minimum order amount")) {
            subtotal += parseFloat(element.price);
        }
    });

    if (order?.minimum_order_amount !== null && order?.minimum_order_amount > 0) {
        subtotal += parseFloat(order?.minimum_order_amount);
    }

    subtotal += parseFloat(getExtraCosts(order, mandatoryProductSettings));
    order.discounts.forEach((discount) => {
        if (discount.fixed) {
            // if (withFixedDiscounts) {
            //     total += parseFloat(discount.discount);
            // } else {
            //     total += 0; // Added as extra cost
            // }
            total += parseFloat(discount.discount);
        } else {
            total += parseFloat(subtotal) * (parseFloat(discount.discount) / 100);
        }
    });
    return total;
};

const getTotalPrice = (order, loggedInUser, setTotalDiscount, mandatoryProductSettings, productSettings) => {
    let totalDiscount = 0;
    let originalTotal = 0;
    let originalTotalWithStaffel = 0;

    if (
        typeof order === "undefined" ||
        order === null ||
        typeof mandatoryProductSettings === "undefined" ||
        mandatoryProductSettings === null
    ) {
        return 0;
    }
    order.models.forEach((element) => {
        if (order.lock_order_prices === true) {
            originalTotal += parseFloat(element.pivot.price) * element.pivot.amount;
            originalTotalWithStaffel += parseFloat(element.pivot.price) * element.pivot.amount;
        } else if (/[a-z]/i.test(element.pivot.price)) {
            // nothing if the price is on request
        } else if (loggedInUser.company !== null && loggedInUser.company.discount_percentage > 0) {
            let discount = 0;
            originalTotal += parseFloat(element.pivot.price) * element.pivot.amount;
            originalTotalWithStaffel +=
                parseFloat(element.pivot.price) * element.pivot.amount * element.pivot.discount_multiplier;
            if (typeof order.discount === "undefined" || order.discount === null) {
                if (loggedInUser.company.fixed_discount === false) {
                    discount =
                        parseFloat(element.pivot.price) *
                        element.pivot.amount *
                        (parseFloat(loggedInUser.company.discount_percentage) / 100);
                }
            } else {
                if (order.fixed_discount === false) {
                    discount =
                        parseFloat(element.pivot.price) * element.pivot.amount * (parseFloat(order.discount) / 100);
                }
            }

            let originalPrice = parseFloat(element.pivot.price) * element.pivot.amount;
            if (loggedInUser.company !== null && loggedInUser.company.allow_staffel_discount == true) {
                discount += originalPrice - originalPrice * parseFloat(element.pivot.discount_multiplier);
            }

            totalDiscount += parseFloat(discount);
        } else if (element.pivot.discount_price !== "undefined" && element.pivot.discount_price !== null) {
            if (order.discounts.length > 0) {
                if (typeof element.pivot.discount_price !== "undefined" && element.pivot.discount_price !== null) {
                    let discount = 0;
                    let regularPrice = parseFloat(element.pivot.price * element.pivot.amount);
                    originalTotalWithStaffel +=
                        parseFloat(element.pivot.price) * element.pivot.amount * element.pivot.discount_multiplier;
                    let quantityDiscount = regularPrice - regularPrice * element.pivot.discount_multiplier;
                    totalDiscount += parseFloat(quantityDiscount);

                    if (order.discounts[0].fixed === false) {
                        discount =
                            parseFloat(element.pivot.price) *
                            element.pivot.amount *
                            parseFloat(order.discounts[0].discount / 100);
                    }
                    totalDiscount += parseFloat(discount);
                    originalTotal += parseFloat(regularPrice);
                } else {
                    // Check if model price is a valid number (not a price 'on request')
                    if (!/[a-z]/i.test(element.pivot.price)) {
                        let discount = 0;
                        if (order.discounts[0].fixed === false) {
                            discount =
                                parseFloat(element.pivot.price) *
                                element.pivot.amount *
                                parseFloat(order.discounts[0].discount / 100);
                        }
                        totalDiscount += parseFloat(discount);
                    }
                }
            } else {
                let regularPrice = parseFloat(element.pivot.price * element.pivot.amount);
                totalDiscount += parseFloat(regularPrice - parseFloat(element.pivot.discount_price));
                originalTotal += (parseFloat(element.pivot.price) * element.pivot.amount).toFixed(2);
                originalTotalWithStaffel += parseFloat(element.pivot.price) * element.pivot.amount;
            }
        } else {
            let regularPrice = parseFloat(element.pivot.price * element.pivot.amount);
            totalDiscount += parseFloat(regularPrice - parseFloat(element.pivot.discount_price));
            originalTotal += (parseFloat(element.pivot.price) * element.pivot.amount).toFixed(2);
            originalTotalWithStaffel += parseFloat(element.pivot.price) * element.pivot.amount;
        }
    });
    let total = parseFloat(getSubtotalPrice(order, loggedInUser, mandatoryProductSettings));

    total += getMinimumOrderCosts(order, loggedInUser, mandatoryProductSettings);

    let companyDiscount = getCompanyDiscount(order, loggedInUser, mandatoryProductSettings, true);
    total -= parseFloat(companyDiscount);

    let discountCodesAmount = getDiscountCodesAmount(order, mandatoryProductSettings, true);
    total -= parseFloat(discountCodesAmount);

    if (total < 0) {
        total = 0;
    }

    order.extraCosts.forEach((element) => {
        if (
            element.name !== null &&
            !element.name.includes("Discount code") &&
            !element.name.includes("Company discount") &&
            !element.name.includes("Minimum order amount")
        ) {
            total += parseFloat(element.price);
        } else if (element.name === null) {
            total += parseFloat(element.price);
        }
    });

    //Company discount
    if (
        loggedInUser.company !== null &&
        loggedInUser.company.discount_percentage > 0 &&
        loggedInUser.company.fixed_discount === true &&
        order.status === "quotation"
    ) {
        totalDiscount += parseFloat(loggedInUser.company.discount_percentage);
    } else if (order.discount !== null && order.fixed_discount === true && order.status !== "quotation") {
        totalDiscount += parseFloat(order.discount);
    }

    if (order.discounts.length > 0) {
        if (order.discounts[0].fixed == false) {
            let discount = parseFloat(order.discounts[0].discount);
            totalDiscount += parseFloat(originalTotalWithStaffel * discount) / 100;
        } else {
            let discount = parseFloat(order.discounts[0].discount);
            totalDiscount += parseFloat(discount);
        }
    }

    let shippingCosts = getShippingCosts(order);
    if (total < 0) {
        total = 0;
        // Add shipping costs again
        if (shippingCosts !== "..." && typeof shippingCosts !== "undefined" && shippingCosts !== null) {
            total += parseFloat(shippingCosts);
        }
    } else if (shippingCosts !== "..." && typeof shippingCosts !== "undefined" && shippingCosts !== null) {
        total += parseFloat(shippingCosts);
    }
    total += calculateVat(order, loggedInUser, mandatoryProductSettings, productSettings);

    // Set discount amount
    if (
        (loggedInUser.company !== null && loggedInUser.company.discount_percentage > 0) ||
        loggedInUser.company === null ||
        (loggedInUser.company !== null && loggedInUser.company.allow_staffel_discount != false && totalDiscount > 0)
    ) {
        setTotalDiscount(parseFloat(totalDiscount).toFixed(2));
    } else if (typeof order.discount !== "undefined" && order.discount !== null && order.discount !== 0) {
        setTotalDiscount(parseFloat(totalDiscount).toFixed(2));
    } else if (order.discounts.length > 0) {
        setTotalDiscount(parseFloat(totalDiscount).toFixed(2));
    }

    return total.toFixed(2);
};

const getSubtotalPrice = (order, loggedInUser, mandatoryProductSettings) => {
    let total = 0;

    if (
        typeof order === "undefined" ||
        order === null ||
        typeof mandatoryProductSettings === "undefined" ||
        mandatoryProductSettings === null
    ) {
        return 0;
    }

    order.models.forEach((element) => {
        if (order.lock_order_prices === true) {
            total += parseFloat(element.pivot.price * element.pivot.amount);
        } else if (element.admin_edited) {
            total +=
                element.pivot.discount_price !== null
                    ? parseFloat(element.pivot.discount_price)
                    : parseFloat(element.pivot.price * element.pivot.amount);
        } else if (/[a-z]/i.test(element.pivot.price)) {
            // nothing if the price is on request
        } else if (element.pivot.discount_price !== "undefined" && element.pivot.discount_price !== null) {
            total += parseFloat(element.pivot.discount_price);
        } else if (
            loggedInUser.company !== null &&
            loggedInUser.company.allow_staffel_discount == true &&
            order.status === "quotation"
        ) {
            total += parseFloat(element.pivot.price * element.pivot.amount) * element.pivot.discount_multiplier;
        } else if (!/[a-z]/i.test(element.pivot.price)) {
            total += parseFloat(element.pivot.price * element.pivot.amount);
        }
    });

    //Get mandatory options costs
    if (getExtraCosts(order, mandatoryProductSettings) !== false) {
        total += parseFloat(getExtraCosts(order, mandatoryProductSettings));
    }

    // Get manual extra costs (from admin panel)
    // order.extraCosts.forEach((element) => {
    //     if (
    //         element.name !== null &&
    //         !element.name.includes("Discount code") &&
    //         !element.name.includes("Company discount")
    //     ) {
    //         total += parseFloat(element.price);
    //     } else if (element.name === null) {
    //         total += parseFloat(element.price);
    //     }
    // });

    // Add library products
    const products = order.products;
    if (typeof products !== "undefined" && products !== null) {
        products.forEach((element) => {
            total += parseFloat(element.price) * parseInt(element.quantity);
        });
    }

    if (total < 0) {
        total = 0;
    }

    if (order?.minimum_order_amount !== null && order?.minimum_order_amount > 0) {
        total += parseFloat(order?.minimum_order_amount);
    }

    return total.toFixed(2);
};

const getExtraCosts = (order, mandatoryProductSettings) => {
    let result = 0;
    let checked = [];
    let checkedMedicals = [];

    if (
        typeof order === "undefined" ||
        order === null ||
        typeof mandatoryProductSettings === "undefined" ||
        mandatoryProductSettings === null
    ) {
        return 0;
    }

    order.models.forEach((model) => {
        mandatoryProductSettings.forEach((setting) => {
            if (model.settings?.application === setting?.application) {
                if (
                    (!checked.includes(setting.application) ||
                        (!checkedMedicals.includes(model.settings.case) &&
                            typeof model.settings.case !== "undefined" &&
                            model.settings.case !== null)) &&
                    setting.application === "Medical" &&
                    (model.settings.sterile === "true" || model.settings.sterile === true)
                ) {
                    result += setting.mandatory_options[0].price;
                    checked.push(setting.application);
                    if (model.settings.case !== null && typeof model.settings.case !== "undefined") {
                        checkedMedicals.push(model.settings.case);
                    }
                } else if (setting.application !== "Medical" && !checked.includes(setting.application)) {
                    result += setting.mandatory_options[0].price;
                    checked.push(setting.application);
                }
            }
        });
    });

    return result.toFixed(2);
};

const getExtraOrderCosts = (order, mandatoryProductSettings, textData) => {
    let counter = 0;
    let manualCosts = order.extraCosts.map((element) => {
        counter++;
        if (element.name === null && element.description !== null) {
            return (
                <div key={counter}>
                    <p>{element.description}</p>
                    <p>€ {element.price}</p>
                </div>
            );
        } else if (
            element.name !== null &&
            !element.name.includes("Discount code") &&
            !element.name.includes("Company discount")
        ) {
            return (
                <div key={counter}>
                    <p>{element.name}</p>
                    <p>€ {element.price}</p>
                </div>
            );
        }
    });

    let checked = [];
    let mandatoryCosts = [];
    let checkedMedicals = [];
    order.models.forEach((model) => {
        mandatoryProductSettings.forEach((setting) => {
            if (model.settings.application === setting.application) {
                if (
                    (!checked.includes(setting.application) &&
                        setting.application === "Medical" &&
                        (model.settings.sterile === "true" || model.settings.sterile === true)) ||
                    (!checkedMedicals.includes(model.settings.case) &&
                        typeof model.settings.case !== "undefined" &&
                        model.settings.case !== null)
                ) {
                    mandatoryCosts.push({
                        application: setting.application,
                        price: setting.mandatory_options[0].price,
                    });
                    checked.push(setting.application);
                    if (model.settings.case !== null && typeof model.settings.case !== "undefined") {
                        checkedMedicals.push(model.settings.case);
                    }
                } else if (setting.application !== "Medical" && !checked.includes(setting.application)) {
                    checked.push(setting.application);
                    mandatoryCosts.push({
                        application: setting.application,
                        price: setting.mandatory_options[0].price,
                    });
                }
            }
        });
    });

    let mandatoryCostsResult = mandatoryCosts.map((element) => {
        counter++;
        return (
            <div key={counter}>
                <p>
                    {textData["popups/order_overview"]["one_time_fee"]}: {element.application}
                </p>
                <p>€ {element.price.toFixed(2)}</p>
            </div>
        );
    });

    let result = [...manualCosts, ...mandatoryCostsResult];
    result = result.filter((element) => element !== undefined);
    return result;
};

const calculateVat = (
    order,
    loggedInUser,
    mandatoryProductSettings,
    productSettings,
    country = null,
    deliveryPrice = null,
    subtotal = null,
    companyDiscount = null,
    totalDiscountCodeAmount = null
) => {
    // Calc subtotal
    const getSubtotal = (
        order,
        loggedInUser,
        mandatoryProductSettings,
        productSettings,
        deliveryPrice = null,
        subtotal = null,
        companyDiscount = null,
        totalDiscountCodeAmount = null
    ) => {
        if (order !== null) {
            let subtotal = getSubtotalPrice(order, loggedInUser, mandatoryProductSettings);
            const shippingCosts = getShippingCosts(order);
            const companyDiscount = getCompanyDiscount(order, loggedInUser, mandatoryProductSettings, true);
            const orderDiscount = getOrderDiscount(order);
            const discountCodesAmount = getDiscountCodesAmount(order, mandatoryProductSettings);

            let manualCosts = 0;
            if (Array.isArray(order.extraCosts)) {
                order.extraCosts.forEach((element) => {
                    if (element.name === null) {
                        manualCosts += parseFloat(element.price);
                    } else if (
                        !element.name.includes("Company discount") &&
                        !element.name.includes("Discount code") &&
                        !element.name.includes("Minimum order amount")
                    ) {
                        manualCosts += parseFloat(element.price);
                    }
                });
            }
            // UPDATE: Remove the manual costs from the subtotal since it was already added there
            subtotal -= manualCosts;

            if (parseFloat(subtotal) - parseFloat(orderDiscount) - parseFloat(discountCodesAmount) < 0) {
                subtotal = shippingCosts + manualCosts;
            } else if (orderDiscount > 0) {
                subtotal =
                    parseFloat(subtotal) -
                    parseFloat(orderDiscount) +
                    parseFloat(manualCosts) -
                    parseFloat(discountCodesAmount);

                if (subtotal < 0) {
                    subtotal = 0;
                }
                subtotal += parseFloat(shippingCosts);
            } else {
                subtotal =
                    parseFloat(subtotal) +
                    parseFloat(manualCosts) -
                    parseFloat(companyDiscount) -
                    parseFloat(discountCodesAmount);
                if (subtotal < 0) {
                    subtotal = 0;
                }

                // add shipping
                subtotal += parseFloat(shippingCosts);
            }

            return subtotal;
        } else {
            if (typeof deliveryPrice !== "undefined" && deliveryPrice !== null) {
                if (parseFloat(subtotal) - parseFloat(companyDiscount) - parseFloat(totalDiscountCodeAmount) < 0) {
                    subtotal = deliveryPrice;
                } else {
                    subtotal =
                        parseFloat(subtotal) -
                        parseFloat(companyDiscount) -
                        parseFloat(totalDiscountCodeAmount) +
                        parseFloat(deliveryPrice);
                }
            } else {
                subtotal = parseFloat(subtotal) - parseFloat(companyDiscount) - parseFloat(totalDiscountCodeAmount);
            }

            return subtotal;
        }
    };

    // Calculate VAT based on country
    const calcVat = (subtotal, country, company, vatNumber, order) => {
        let subTotalLocal = subtotal;
        if (subTotalLocal < 0) {
            subTotalLocal = 0;
        }

        // Check if order
        if (country === null && order !== null) {
            // Check if country is set in delivery details
            if (typeof order.deliveryDetails !== "undefined" && order.deliveryDetails !== null) {
                country = order.deliveryDetails.country;
            }

            if (typeof order?.delivery_country !== "undefined" && order?.delivery_country !== null) {
                country = order?.delivery_country;
            }

            // Check if status offerte
            if (order.status !== "order" && company !== null && typeof company?.country !== "undefined") {
                country = company.country;
            } else if (company === null && typeof loggedInUser?.user?.country !== "undefined") {
                country = loggedInUser.user.country;
            }
        }

        let vat = 0;
        if (company !== null && typeof country !== "undefined" && country !== null) {
            if (
                (isEU(country) && vatNumber !== null && vatNumber !== "" && country !== "Netherlands") ||
                !isEU(country)
            ) {
                vat = 0;
            } else if (isEU(country)) {
                vat = subTotalLocal * 0.21;
            }
        } else {
            vat = subTotalLocal * 0.21;
        }

        return vat;
    };

    let subt = getSubtotal(
        order,
        loggedInUser,
        mandatoryProductSettings,
        productSettings,
        deliveryPrice,
        subtotal,
        companyDiscount,
        totalDiscountCodeAmount
    );

    let result = calcVat(
        subt,
        country,
        loggedInUser.company,
        typeof loggedInUser?.company !== "undefined" &&
            typeof loggedInUser?.company?.vat_number !== "undefined" &&
            loggedInUser?.company?.vat_number !== ""
            ? loggedInUser.company.vat_number
            : null,
        order
    );

    if (result < 0) {
        result = 0;
    }

    // Check if there is a minimum order amount
    if (
        order?.status === "quotation" &&
        loggedInUser?.company !== null &&
        loggedInUser?.company?.minimum_order_amount !== null &&
        loggedInUser?.company?.minimum_order_amount > subt
    ) {
        let subtotal = getSubtotalPrice(order, loggedInUser, mandatoryProductSettings);
        if (subtotal < loggedInUser.company.minimum_order_amount) {
            // Get the difference between the minimum order amount and the subtotal
            let companyDisc = getCompanyDiscount(order, loggedInUser, mandatoryProductSettings, true);
            if (companyDisc > 0) {
                let difference = loggedInUser.company.minimum_order_amount - companyDisc;
                result += difference * 0.21;
            } else {
                let oldVat = subtotal * 0.21;
                result += loggedInUser.company.minimum_order_amount * 0.21 - oldVat;
            }
        }
    }
    return result;
};

const getMinimumOrderCosts = (order, loggedInUser, mandatoryProductSettings) => {
    if (
        order?.status === "quotation" &&
        loggedInUser?.company !== null &&
        loggedInUser?.company?.minimum_order_amount !== null &&
        loggedInUser?.company?.minimum_order_amount > 0
    ) {
        // Check if the amount is less than the minimum order amount
        let subtotal = getSubtotalPrice(order, loggedInUser, mandatoryProductSettings);
        if (subtotal < loggedInUser.company.minimum_order_amount) {
            // Get the difference between the minimum order amount and the subtotal
            let difference = loggedInUser.company.minimum_order_amount - subtotal;
            return difference;
        }
    }

    return 0;
};

export {
    getDiscountCodesAmount,
    calculateVat,
    getOrderDiscount,
    getCompanyDiscount,
    getTotalPrice,
    getSubtotalPrice,
    getExtraCosts,
    getExtraOrderCosts,
    getModelPrice,
    getMinimumOrderCosts,
};
